body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.complementary-gold{
  /* background-image: linear-gradient(#AD974F, #DABF66, #AD974F) !important; */
  background-image: linear-gradient(#AD974F,#DABF66, #f0da91,#DABF66, #AD974F) !important;
}

.white-link {
  color: #DABF66; /* Set text color to white */
  text-decoration: none; /* Remove default underline */
}

.white-link:hover {
  text-decoration: underline; /* Add underline on hover */
  color: white; /* Maintain white text color on hover */
}

.cart-button:hover .cart-icon {
  color: black;
}

.primary-background{
  background-color: #252525;
}

.secondary-background{
  background-color: #363636;
}

.mask-custom {
  backdrop-filter: blur(6px);
  background-color: rgba(31,28,33,0.4);
}

.gold-circle {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  background-image: linear-gradient(#AD974F, #DABF66, #AD974F);
  border-radius: 50%; /* Creates a circle */
  background-position: center center;
  background-size: cover;
}

.typing-indicator {
  display: inline-block;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: black; /* Changed to black */
  opacity: 0; /* Initially hide the dots */
}

/* Pseudo-element to trigger animation sequence */
.typing-indicator::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  animation: dot-sequence 3s ease-in-out infinite; /* Animation duration reduced to 3s */
}

@keyframes dot-sequence {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1; /* Trigger first dot animation (after initial hiding) */
  }
  40% {
    opacity: 0; /* Hide trigger to ensure full opacity change */
  }
  /* Animation delays and opacity changes for subsequent dots */
  50% {
    opacity: 1; /* Trigger second dot animation (after first disappears) - Adjusted timing */
  }
  70% {
    opacity: 0; /* Hide trigger */
  }
  80% {
    opacity: 1; /* Trigger third dot animation (after second disappears) - Adjusted timing */
  }
  100% {
    opacity: 0; /* Reset for next cycle */
  }
}

/* Individual dot animations (ensure these are defined) */
.dot:nth-child(1) {
  animation: dot-appear-1 0.6s ease-in-out alternate infinite; /* Animation duration reduced to 0.6s */
  animation-delay: 0.1s; /* Delay to ensure full opacity change before next dot */
}

@keyframes dot-appear-1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Similar definitions for dot-appear-2 and dot-appear-3 animations for 2nd and 3rd dots with adjusted delays based on new overall duration */
.dot:nth-child(2) {
  animation: dot-appear-2 0.6s ease-in-out alternate infinite;
  animation-delay: 0.7s; /* Delay adjusted */
}

@keyframes dot-appear-2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* extra generated */

/* HERO CSS + MOBILE */


 /* Button spacing */
 #root .container-fluid .row .col div .complementary-gold{
  margin-right:5% !important;
 }
 
 /* card */
 .grid-container {
  display: grid;
  grid-template-columns: 1fr auto; /* Adjust width as needed */
  gap: 1rem; /* Adjust spacing between elements */
}

.card {
  grid-column: 2; /* Place card in second column (right side) */
  max-width: 600vh; /* Set maximum width for card */
}
/* Paragraph */
@media (min-width: 992px) {
  #root .container-fluid .grid-container .card .card-body p{
    width:60% !important;
 }
}

 .pattern {  /* Replace ".container" with the class of your container element */
  background-image: url("../public/graphics/Pattern.png"); /* Replace "path/to/your/pattern.jpg" with the actual path to your image */
  background-repeat: repeat; /* Controls how the pattern repeats (optional) */
  background-size: cover; /* Controls how the image fits the container (optional) */
  background-position: center; /* Controls the position of the image (optional) */
}

/* gold button test */
.btn-1{
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: linear-gradient(180deg,#fea 0%,#dc8 49%,#a95 51%,#dc8 100%);
  border-radius: 5px;
}

.btn-2:hover {
  background-image: linear-gradient(#AD974F, #DABF66, #AD974F) !important;
}

.btn-outline-gold {
  color: #333 !important; /* Set text color here (optional) */
  border-color: #DAA520 !important; /* Your gold color */
  
}

/* gold color override */
.border-gold {
  border-color: #AD974F !important; /* Use !important only here if necessary */
}
/* plyr */
.plyr.plyr--loading::after {
   /* content: "Loading..."; You can replace this with an image or spinner */
  content: url("../public/graphics/Animation - 1723572472456.gif");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF; /* Adjust color to fit your design */
  font-size: 20px; /* Adjust font size as needed */
  z-index: 10;
}